// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import notification from 'antd/lib/notification';
import Spin from 'antd/lib/spin';
import Text from 'antd/lib/typography/Text';
import { SettingOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as math from 'mathjs';

import CVATTooltop from 'components/common/cvat-tooltip';
import { CombinedState } from 'reducers';
import { sum } from 'lodash';
import ContextImageSelector from './context-image-selector';
import sensorCalib from './sensor_calibrations.json';
import newSensorCalib from './new_sensor_calibration.json';

interface Props {
    offset: number[];
    canvasInstance?: any;
    points: any;
    cubeObject: any;
}

type Vector3D = math.Matrix;
type Quaternion = [number, number, number, number];
type Translation = math.Matrix;
type RotationMatrix = math.Matrix;
type Point = [number, number];
type Corners = Point[];

function applyMatrix4(matrix: any, corner: any): [any, any, any] {
    const [x, y, z] = corner;
    const transformedX = matrix[0] * x + matrix[4] * y + matrix[8] * z + matrix[12];
    const transformedY = matrix[1] * x + matrix[5] * y + matrix[9] * z + matrix[13];
    const transformedZ = matrix[2] * x + matrix[6] * y + matrix[10] * z + matrix[14];
    return [transformedX, transformedY, transformedZ];
}

function extractCubeCorners(data: any): any {
    const unitCubeCorners = [
        [-0.5, -0.5, -0.5],
        [0.5, -0.5, -0.5],
        [-0.5, 0.5, -0.5],
        [0.5, 0.5, -0.5],
        [-0.5, -0.5, 0.5],
        [0.5, -0.5, 0.5],
        [-0.5, 0.5, 0.5],
        [0.5, 0.5, 0.5],
    ];

    const matrix = data.perspective.matrix.elements;
    console.log('Matrix and corner', matrix);
    return unitCubeCorners.map((corner) => applyMatrix4(matrix, corner));
}

function arrayToMatrix(array: number[] | number[][]): math.Matrix {
    return math.matrix(array);
}

function quaternionToRotationMatrix(quat: Quaternion): RotationMatrix {
    const [w, x, y, z] = quat;
    return math.matrix([
        [1 - 2 * y ** 2 - 2 * z ** 2, 2 * x * y - 2 * z * w, 2 * x * z + 2 * y * w],
        [2 * x * y + 2 * z * w, 1 - 2 * x ** 2 - 2 * z ** 2, 2 * y * z - 2 * x * w],
        [2 * x * z - 2 * y * w, 2 * y * z + 2 * x * w, 1 - 2 * x ** 2 - 2 * y ** 2],
    ]) as RotationMatrix;
}

function transformPoint(point: Vector3D, rotation: Quaternion, translation: Translation): Vector3D {
    const rotationMatrix = quaternionToRotationMatrix(rotation);
    return math.add(math.multiply(rotationMatrix, point), translation) as Vector3D;
}

function arrayToQuaternion(array: number[]): Quaternion {
    if (array.length === 4) {
        return [array[0], array[1], array[2], array[3]] as Quaternion;
    }
    throw new Error('Array must have exactly four elements to be converted to a Quaternion.');
}

function inverseTransformPoint(point: Vector3D, rotation: Quaternion, translation: Translation): Vector3D {
    const rotationMatrix = quaternionToRotationMatrix(rotation);
    const inverseRotation = math.inv(rotationMatrix) as RotationMatrix;
    const inverseTranslation = math.multiply(-1, math.multiply(inverseRotation, translation));
    return math.add(math.multiply(inverseRotation, point), inverseTranslation) as Vector3D;
}

function projectPointToImage(point: math.Matrix, cameraIntrinsic: math.Matrix): [number, number] {
    const projectedPoint = math.multiply(cameraIntrinsic, point);
    const uValue = math.subset(projectedPoint, math.index(0));
    const vValue = math.subset(projectedPoint, math.index(1));
    const wValue = math.subset(projectedPoint, math.index(2));

    if (typeof uValue !== 'number' || typeof vValue !== 'number' || typeof wValue !== 'number') {
        throw new Error('Expected numerical values in projection calculation.');
    }

    const u = uValue / wValue;
    const v = vValue / wValue;
    return [Math.round(u), Math.round(v)];
}

function transformAndProjectCorners(
    corners3D: number[][],
    cameraCalib: any,
    cameraPose: any,
    lidarCalib: any,
    lidarPose: any,
): [number, number][] {
    const transformedCorners: [number, number][] = [];
    for (const corner of corners3D) {
        const cornerMatrix = arrayToMatrix(corner); // Convert array to Matrix
        let globalPoint = transformPoint(
            cornerMatrix,
            arrayToQuaternion(lidarCalib.rotation),
            arrayToMatrix(lidarCalib.translation),
        );

        globalPoint = transformPoint(
            globalPoint,
            arrayToQuaternion(lidarPose.rotation),
            arrayToMatrix(lidarPose.translation),
        );

        let camPoint = inverseTransformPoint(
            globalPoint,
            arrayToQuaternion(cameraPose.rotation),
            arrayToMatrix(cameraPose.translation),
        );
        camPoint = inverseTransformPoint(
            camPoint,
            arrayToQuaternion(cameraCalib.rotation),
            arrayToMatrix(cameraCalib.translation),
        );

        const projectedPoint = projectPointToImage(camPoint, arrayToMatrix(cameraCalib.camera_intrinsic));
        transformedCorners.push([Math.round(projectedPoint[0]), Math.round(projectedPoint[1])]);
    }
    return transformedCorners;
}

function imagePixelsForCorners(
    corners3D: number[][],
    extrinsics: number[][],
    intrinsics: number[][],
): [number, number][] {
    const transformedCorners: [number, number][] = [];
    const transformationMatrix = arrayToMatrix(extrinsics);
    console.log('Transformation matrix', transformationMatrix);
    for (const corner of corners3D) {
        const cornerMatrix = math.transpose(arrayToMatrix(corner.concat([1.0])));

        // Transform corners3D to camera coordinate system using the extrinsics matrix
        const transformedPoints = math.multiply(transformationMatrix, cornerMatrix) as math.Matrix;

        const projectedPoint = projectPointToImage(transformedPoints, arrayToMatrix(intrinsics));
        transformedCorners.push([Math.round(projectedPoint[0]), Math.round(projectedPoint[1])]);
    }
    return transformedCorners;
}

function reshape(array: number[], shape: number[]): number[][] {
    // write a code to reshape the array with a give shape
    const reshapedArray: number[][] = [];
    let k = 0;
    for (let i = 0; i < shape[0]; i++) {
        reshapedArray.push([]);
        for (let j = 0; j < shape[1]; j++) {
            reshapedArray[i].push(array[k]);
            k++;
        }
    }
    return reshapedArray;
}

function drawCube(context: any, projectedPoints: any, color: any, text: number): void {
    console.log('PP', projectedPoints);
    const edges = [
        [0, 1],
        [1, 3],
        [3, 2],
        [2, 0], // bottom face
        [4, 5],
        [5, 7],
        [7, 6],
        [6, 4], // top face
        [0, 4],
        [1, 5],
        [2, 6],
        [3, 7], // connecting edges
    ];
    context.strokeStyle = color;
    context.lineWidth = 2;
    edges.forEach(([start, end]) => {
        context.beginPath();
        context.moveTo(projectedPoints[start][0], projectedPoints[start][1]);
        context.lineTo(projectedPoints[end][0], projectedPoints[end][1]);
        context.stroke();
        context.closePath();
    });

    context.fillStyle = '#fff';
    const objectId = `Object ID: ${text}`;
    context.fillRect(projectedPoints[0][0], projectedPoints[0][1] - 23, 130, 26);
    context.font = '24px Arial';
    context.fillStyle = color;
    context.fillText(objectId, projectedPoints[0][0], projectedPoints[0][1]);
}

function ContextImage(props: Props): JSX.Element {
    const { offset, cubeObject } = props;
    const defaultFrameOffset = offset[0] || 0;
    const defaultContextImageOffset = offset[1] || 0;

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const job = useSelector((state: CombinedState) => state.annotation.job.instance);
    const { number: frame, relatedFiles } = useSelector((state: CombinedState) => state.annotation.player.frame);
    const frameIndex = frame + defaultFrameOffset;

    const [contextImageData, setContextImageData] = useState<Record<string, ImageBitmap>>({});
    const [fetching, setFetching] = useState<boolean>(false);
    const [contextImageOffset, setContextImageOffset] = useState<number>(
        Math.min(defaultContextImageOffset, relatedFiles),
    );
    const lidarCalib = sensorCalib.LIDAR_TOP.sensor_calibration;
    const lidarPose = sensorCalib.LIDAR_TOP.ego_pose;
    const cameraCalib = sensorCalib.CAM_FRONT.sensor_calibration;
    const cameraPose = sensorCalib.CAM_FRONT.ego_pose;
    const extrinsicMatrix = reshape(newSensorCalib.extrinsic, [4, 4]).slice(0, 3);
    const intrinsicMatrix = reshape(newSensorCalib.intrinsic, [4, 4])
        .slice(0, 3)
        .map((row) => row.slice(0, 3));

    const [zoomLevel, setZoomLevel] = useState<number>(1);
    const [mousePos, setMousePos] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [dragStart, setDragStart] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [dragOffset, setDragOffset] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const dragSpeed = 1.001;
    const [hasError, setHasError] = useState<boolean>(false);
    const [showSelector, setShowSelector] = useState<boolean>(false);
    const { canvasInstance } = props;
    const { drawnObjects } = canvasInstance.view;

    useEffect(() => {
        if (canvasRef.current) {
            const sortedKeys = Object.keys(contextImageData).sort();
            const key = sortedKeys[contextImageOffset];
            const image = contextImageData[key];
            const context = canvasRef.current.getContext('2d');
            const tempCorners: Corners[] = [];
            const cuboidColors: string[] = [];
            const cuboidIDs: number[] = [];

            if (context && image) {
                canvasRef.current.width = image.width;
                canvasRef.current.height = image.height;
                context.setTransform(zoomLevel, 0, 0, zoomLevel, dragOffset.x, dragOffset.y);
                context.drawImage(image, 0, 0);
                if (drawnObjects) {
                    Object.keys(drawnObjects).forEach((cuboidKey) => {
                        const cuboid = drawnObjects[cuboidKey];
                        if (cuboid) {
                            // Assuming cuboid.data holds the necessary cuboid info
                            const corners = extractCubeCorners(cuboid.cuboid);
                            const { clientID, labelColor } = cuboid.data;
                            const yPoints = [];
                            for (let i = 0; i < 8; i++) {
                                yPoints.push(corners[i][1] < 0 ? 1 : 0);
                            }
                            if (sum(yPoints) < 8) {
                                const transformed2DCorners = transformAndProjectCorners(
                                    corners,
                                    cameraCalib,
                                    cameraPose,
                                    lidarCalib,
                                    lidarPose,
                                );
                                const transformed2DCorners2 = imagePixelsForCorners(
                                    corners,
                                    extrinsicMatrix,
                                    intrinsicMatrix,
                                );
                                console.log('Transformed corners 1', transformed2DCorners);
                                console.log('Transformed corners 2', transformed2DCorners2);
                                tempCorners.push(transformed2DCorners);
                                cuboidColors.push(labelColor);
                                cuboidIDs.push(clientID);
                            } else {
                                console.log('Not calculating the cube', yPoints);
                            }
                        }
                    });
                }

                if (key === '' || key.toLocaleLowerCase().includes('001_key_projections')) {
                    tempCorners.forEach((corners, index) => {
                        const cornerOutOfBounds: number[] = [];
                        corners.forEach((corner) => {
                            if (corner[0] > image.width || corner[1] > image.height || corner[0] < 0 || corner[1] < 0) {
                                cornerOutOfBounds.push(1);
                            }
                        });
                        if (sum(cornerOutOfBounds) > 0) {
                            console.log('Not drawing the cube as it is out of bounds.');
                            return;
                        }
                        drawCube(context, corners, cuboidColors[index], cuboidIDs[index]);
                    });
                }
                context.translate(dragOffset.x, dragOffset.y);
            }
        }
    }, [cubeObject, contextImageData, contextImageOffset, canvasRef, zoomLevel, mousePos, dragOffset, drawnObjects]);

    const handleWheel = (event: WheelEvent): void => {
        event.preventDefault();
        const rect = canvasRef.current.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        setMousePos({ x, y });
        const zoomSpeed = 0.1;
        setZoomLevel((prevZoomLevel) => {
            const newZoomLevel = prevZoomLevel + (event.deltaY > 0 ? -zoomSpeed : zoomSpeed);
            return Math.max(0.1, Math.min(newZoomLevel, 10));
        });
    };
    const handleMouseDown = (event: MouseEvent): void => {
        setIsDragging(true);
        setDragStart({ x: event.clientX, y: event.clientY });
    };

    const handleMouseMove = (event: MouseEvent): void => {
        if (isDragging) {
            setDragOffset((prevOffset) => ({
                x: prevOffset.x * dragSpeed + (event.clientX - dragStart.x),
                y: prevOffset.y * dragSpeed + (event.clientY - dragStart.y),
            }));
            setDragStart({ x: event.clientX, y: event.clientY });
        }
    };

    const handleMouseUp = (): void => {
        setIsDragging(false);
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            canvas.addEventListener('wheel', handleWheel);
            canvas.addEventListener('mousedown', handleMouseDown);
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
            return (): void => {
                canvas.removeEventListener('wheel', handleWheel);
                canvas.removeEventListener('mousedown', handleMouseDown);
                window.removeEventListener('mousemove', handleMouseMove);
                window.removeEventListener('mouseup', handleMouseUp);
            };
        }
        return () => null;
    }, [handleWheel, handleMouseDown, handleMouseMove, handleMouseUp]);

    useEffect(() => {
        let unmounted = false;
        const promise = job.frames.contextImage(frameIndex);
        setFetching(true);
        promise
            .then((imageBitmaps: Record<string, ImageBitmap>) => {
                if (!unmounted) {
                    setContextImageData(imageBitmaps);
                }
            })
            .catch((error: any) => {
                if (!unmounted) {
                    setHasError(true);
                    notification.error({
                        message: `Could not fetch context images. Frame: ${frameIndex}`,
                        description: error.toString(),
                    });
                }
            })
            .finally(() => {
                if (!unmounted) {
                    setFetching(false);
                }
            });

        return () => {
            setContextImageData({});
            unmounted = true;
        };
    }, [frameIndex]);

    const contextImageName = Object.keys(contextImageData).sort()[contextImageOffset];
    return (
        <div className='cvat-context-image-wrapper'>
            <div className='cvat-context-image-header'>
                {relatedFiles > 1 && (
                    <SettingOutlined
                        className='cvat-context-image-setup-button'
                        onClick={() => {
                            setShowSelector(true);
                        }}
                    />
                )}
                <div className='cvat-context-image-title'>
                    <CVATTooltop title={contextImageName}>
                        <Text>{contextImageName}</Text>
                    </CVATTooltop>
                </div>
            </div>
            {(hasError || (!fetching && contextImageOffset >= Object.keys(contextImageData).length)) && (
                <Text> No data </Text>
            )}
            {fetching && <Spin size='small' />}
            {contextImageOffset < Object.keys(contextImageData).length && <canvas ref={canvasRef} />}
            {showSelector && (
                <ContextImageSelector
                    images={contextImageData}
                    offset={contextImageOffset}
                    onChangeOffset={(newContextImageOffset: number) => {
                        setContextImageOffset(newContextImageOffset);
                    }}
                    onClose={() => {
                        setShowSelector(false);
                    }}
                />
            )}
        </div>
    );
}

ContextImage.PropType = {
    offset: PropTypes.arrayOf(PropTypes.number),
    // eslint-disable-next-line react/forbid-prop-types
    canvasInstance: PropTypes.any,
};

export default React.memo(ContextImage);
